import { AdviesBoxFormik, LabeledSelectInput, LabeledText, LabeledTextInput } from "adviesbox-shared";
import { FormikProps, getIn, setIn, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { LabelValuePairs } from "../../types";
import Modal from "../modal/Modal";
import { RapportageSamenstellenState } from "./infra/rapportage-samenstellen-types";
import { rapportageSamenstellenSchema } from "./rapportage-samenstellen-schema";

type TitelAanpassen = {
  onSave?: (data: any) => void;
  closeModal?: () => void;
  parent: string;
  adoptionList?: LabelValuePairs;
};

export const TitelAanpassenModal = ({ onSave, closeModal, parent, adoptionList }: TitelAanpassen): ReactElement => {
  const { values } = useFormikContext<RapportageSamenstellenState>();
  const body = (
    <>
      <LabeledText value={getIn(values, `${parent}.naam`)} caption={"Huidige titel"} />
      <LabeledTextInput name={`${parent}.naam`} caption={"Nieuwe titel"} />
      {adoptionList && (
        <LabeledSelectInput caption={"Dit element verplaatsen naar"} name={"movetoparent"} options={adoptionList} />
      )}
    </>
  );

  return (
    <AdviesBoxFormik<RapportageSamenstellenState>
      initialValues={{ ...values }}
      validationSchema={rapportageSamenstellenSchema}
      onSave={values => {
        let newValues = values;

        if (values.movetoparent) {
          const elementToMove = getIn(newValues, parent);
          const arrayToAddTo = getIn(newValues, values.movetoparent);

          const nameList = parent.split(".");

          nameList.pop();

          const parentListName = nameList.join(".");

          const parentList = getIn(newValues, parentListName).elementen;

          const filteredParentList = parentList
            .filter((c: any) => {
              return c !== elementToMove;
            })
            .map((c: any, i: number) => ({ ...c, volgnummer: i + 1 }));

          newValues = setIn(newValues, `${parentListName}.elementen`, filteredParentList);
          newValues = setIn(
            newValues,
            `${values.movetoparent}.elementen`,
            [...arrayToAddTo.elementen, elementToMove].map((c: any, i: number) => ({ ...c, volgnummer: i + 1 }))
          );
        }
        onSave && onSave(newValues);
      }}
      closeModal={closeModal}
      render={({ submitForm }: FormikProps<RapportageSamenstellenState>): ReactElement => (
        <>
          <Modal title="Titel aanpassen" body={body} onSubmitClick={submitForm} onCancelClick={closeModal} />
        </>
      )}
    />
  );
};
