import { AuthContext, getAppLocation, getCookie, getOTAP, SettingsContext } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { AuthenticatedInstellingenApp } from "./AuthenticatedInstellingenApp";
import UnauthenticatedInstellingenApp from "./UnauthenticatedInstellingenApp";

const InstellingenApp = (): ReactElement => {
  const { user } = useContext(AuthContext);
  const { baseUrls } = useContext(SettingsContext);
  const linkToPortal = getAppLocation(window.location.origin, "INS", "POR", baseUrls);
  const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);

  if (!vestigingIdCookie) window.location.assign(linkToPortal);

  if (user) {
    return (
      <div data-testid="authenticated-routes">
        <Switch>
          <Route path="/vestiging/:vestiging" component={AuthenticatedInstellingenApp} />
        </Switch>
      </div>
    );
  }

  return <UnauthenticatedInstellingenApp />;
};

InstellingenApp.displayName = "InstellingenApp";

export default InstellingenApp;
