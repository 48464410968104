import React, { ReactElement, useState } from "react";
import {
  LabeledTextInput,
  FormFirstFocus,
  PlatformFoutenSamenvatting,
  useRequestInit,
  ErrorPage,
  PageLoading,
  AdviesBoxFormik,
} from "adviesbox-shared";
import Modal from "../../shared/components/modal/Modal";
import useAbortableFetch from "use-abortable-fetch";
import { ImportkoppelingOutput } from "../../.generated/instellingen-forms/instellingen-formstypes";
import { FormikProps } from "formik";
import {
  ImportkoppelingInstellingenType,
  importkoppelingInstellingenSchema,
} from "../infra";
import { v4 as uuidv4 } from "uuid";
import { importkoppelingInstellingenModalApi } from "./importkoppeling-instellingen-modal-api";
import { Button } from "react-bootstrap";

interface ImportkoppelingInstellingenModalProps {
  onSave?: () => void;
  closeModal?: () => void;
  importkoppelingId: string;
}

export const ImportkoppelingInstellingenModal = ({
  onSave,
  closeModal,
  importkoppelingId,
}: ImportkoppelingInstellingenModalProps): ReactElement => {
  const { settings, user, params } = useRequestInit<{
    vestiging: string;
  }>();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { requestInit } = useRequestInit();
  const url = `${settings.instellingenFormsOrigin}/ExterneKoppelingen/Importkoppelingen/${importkoppelingId}`;

  const { data, loading, error } = useAbortableFetch<ImportkoppelingOutput>(
    url,
    requestInit
  );

  /* istanbul ignore next */
  if (error || typeof data === "string") {
    const errorMessage = error
      ? error
      : new Error("Het ophalen van de importkoppelingsinstellingen is mislukt");
    return <ErrorPage error={errorMessage} />;
  }

  /* istanbul ignore next */
  if (loading || !data) {
    return <PageLoading />;
  }

  const importkoppelingInstellingen = data.importkoppeling as ImportkoppelingInstellingenType;

  const handleGenerateGUID = (
    setFieldValue: (field: string, value: any) => void
  ): void => {
    setFieldValue("koppelingKey", uuidv4());
  };

  const renderForm = ({
    setFieldValue,
  }: FormikProps<ImportkoppelingInstellingenType>): ReactElement => (
    <>
      <PlatformFoutenSamenvatting />
      <FormFirstFocus>
        <LabeledTextInput
          caption="Koppeling GUID"
          tooltip="Unieke identificatie voor de importkoppeling"
          name="koppelingKey"
          appendChildren={
            <Button
              data-testid="guid-genereren"
              onClick={() => handleGenerateGUID(setFieldValue)}
            >
              GUID genereren
            </Button>
          }
        />
      </FormFirstFocus>
    </>
  );

  const handleSubmit = async (
    values: ImportkoppelingInstellingenType
  ): Promise<void> => {
    setSubmitDisabled(true);
    await importkoppelingInstellingenModalApi(
      settings,
      user,
      params.vestiging,
      importkoppelingId,
      values
    );
    setSubmitDisabled(false);
  };

  return (
    <AdviesBoxFormik<ImportkoppelingInstellingenType>
      initialValues={importkoppelingInstellingen}
      validationSchema={importkoppelingInstellingenSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={(formikProps: FormikProps<ImportkoppelingInstellingenType>) => (
        <Modal
          onCancelClick={closeModal}
          title="Instellingen wijzigen"
          body={renderForm(formikProps)}
          onSubmitClick={async () => {
            await handleSubmit(formikProps.values);
            await formikProps.submitForm();
          }}
          submitDisabled={
            submitDisabled || !formikProps.dirty || !formikProps.isValid
          }
        />
      )}
    />
  );
};
