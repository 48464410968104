import React from "react";

import { getCookie, getOTAP, SettingsContext, getAppLocation } from "adviesbox-shared";
import { Redirect } from "react-router-dom";
import { useContext, ReactElement } from "react";

export const RedirectToKoppeling = (): ReactElement => {
  const { baseUrls } = useContext(SettingsContext);
  const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);

  if (vestigingIdCookie) {
    return ( <Redirect to={`/vestiging/${vestigingIdCookie}/koppeling`} />);
  
  }

  const linkToPortal = getAppLocation(window.location.origin, "INS", "POR", baseUrls);
  const linkToInst = getAppLocation(window.location.origin, "INS", "INS", baseUrls);

  return (
    <div>
      Log eerst in op het <a href={linkToPortal}>Portaal</a> en bezoek daarna deze pagina{" "}
      <a href={`${linkToInst}/koppeling`}>Koppeling</a> pagina opnieuw.
    </div>
  );
};
