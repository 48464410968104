/* istanbul ignore file */

import {
  AuthProvider,
  ErrorBoundaryContextProvider,
  SettingsProvider,
  SigninSilentOidc,
  withErrorBoundary,
  RouteEvents
} from "adviesbox-shared";
import React, { ReactElement, useRef, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import logo from "./assets/adviesbox-logo.svg";
import AutorisatieNiveauProvider from "./autorisatie-niveau-context/autorisatie-niveau-provider";
import InstellingenApp from "./instellingen-app/InstellingenApp";
import ValidatiePopup from "./shared/components/validatie-popup/validatie-popup";
import "./styles/style.scss";
import UserDetailsProvider from "./user-details/user-details-provider";
import VestigingenProvider from "./vestigingen-context/vestigingen-provider";
import { RedirectToKoppeling } from "./abc-koppeling/abc-koppeling-redirect";

const App: React.FC = () => {
  const [modalShow, setModalShow] = useState(false);
  const continueRef = useRef<any>(null);

  return (
    <div className="wrapper">
      <ValidatiePopup
        infotekst="browserInfo"
        show={modalShow}
        onHide={(): void => {
          setModalShow(false);
          continueRef.current(false);
        }}
        onSubmit={(): void => {
          setModalShow(false);
          if (continueRef.current) {
            continueRef.current(true);
          }
        }}
        navigatiepopup
      />
      <ErrorBoundaryContextProvider>
        <BrowserRouter
          getUserConfirmation={(_message: string, callback: (ok: boolean) => void): void => {
            continueRef.current = callback;

            setModalShow(true);
          }}
        >
          <SettingsProvider loadingLogo={logo}>
            <Switch>
              <Route path="/silent-redirect" component={withErrorBoundary(SigninSilentOidc)} />
              <Route path="/koppeling" component={RedirectToKoppeling} exact/>

              <Route
                render={(): ReactElement => (
                  <AuthProvider>
                    <RouteEvents>
                      <AutorisatieNiveauProvider>
                        <VestigingenProvider>
                          <UserDetailsProvider>
                            <InstellingenApp />
                          </UserDetailsProvider>
                        </VestigingenProvider>
                      </AutorisatieNiveauProvider>
                    </RouteEvents>
                  </AuthProvider>
                )}
              />
            </Switch>
          </SettingsProvider>
        </BrowserRouter>
      </ErrorBoundaryContextProvider>
    </div>
  );
};

export default withErrorBoundary(App);
